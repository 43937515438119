import { CountryCode } from "@/common/config"
import { Company } from "../companies/bo"

export type WatchlistBO = {
    id: string,
    name: string,
    country_code: CountryCode,
    criteria?: {
        companies?: Company[]
    },
    notification_settings?: {
        document_types?: string[]
    }
}

export type WatchlistCriteria = {
    companies: string[]
}

export type UserWatchlistBO = { user_email: string } & WatchlistBO

export type WatchlistSummaryBO = {
    id: string,
    name: string,
    country_code: CountryCode,
}

export enum UpdateOp {
    RENAME = "rename",
    ADD = "add",
    REMOVE = "remove",
}

export enum UpdateField {
    NAME = "name",
    COMPANY = "companies"
}