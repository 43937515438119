import {
	IOnboardingTitleParams,
	OnboardingPagePropType,
} from "@/client/lib/types/onboarding-types"

// PropType for a constant in unnecessary. can be removed
export const investorTypeSelection: OnboardingPagePropType = {
	title: "Which type of investor are you?",
	sub: "You can change it later from your account profile.",
	radioFields: [
		{
			value: "Individual Investor",
			radioOption: "Individual Investor",
			exchanges: ["NSE", "BSE"],
		},
		{
			value: "Equity Analyst",
			radioOption: "Equity Analyst",
			exchanges: ["NSE", "BSE"],
		},
		{
			value: "Financial Advisor",
			radioOption: "Financial Advisor",
			exchanges: ["NSE", "BSE"],
		},
		{
			value: "Other",
			radioOption: "Other",
			exchanges: ["NSE", "BSE"],
		},
	],
}

export const DEFAULT_INVESTOR_TYPES = investorTypeSelection.radioFields
	.slice(0, investorTypeSelection.radioFields.length - 1)
	.map(item => item.radioOption)

export const INVESTOR_TYPE_OTHER =
	investorTypeSelection.radioFields[
		investorTypeSelection.radioFields.length - 1
	].radioOption

export const userNamePrompt: IOnboardingTitleParams = {
	title: "What should we call you?",
	sub: "Name",
}
