import {
	CountryCode,
	DocumentTypesIN,
	DocumentTypesUS,
	InStockExchange,
} from "@/common/config"
import { parse } from "best-effort-json-parser"
import { zonedTimeToUtc } from "date-fns-tz"

/**
 * convert yearQuarterString to array of objects with year and quarter
 *
 * @param   yearQuarterString  example string -> "2023_Q2,2024_Q2"
 * @returns output -> [{year: 2023, quarter: Q2}, {year: 2024, quarter: Q2}]
 */
export const getYearQuarter = (yearQuarterString: string | string[]) => {
	const result: { year: string; quarter: string }[] = []
	let yearQuarterArray = yearQuarterString
	if (typeof yearQuarterArray === "string") {
		yearQuarterArray = yearQuarterArray.split(",")
	}
	yearQuarterArray.forEach(val => {
		const [year, quarter] = val.split("_")
		result.push({ year, quarter })
	})
	return result
}

/**
 * convert tickerString to object with nseTickers, bseTickers and tickersWithStockExchangeNotMentioned
 *
 * @param   tickerString  example string -> "NSE:TCS,BSE:INFY,OLECTRA"
 * @returns example output -> {
  "nseTickers": [
	"TCS"
  ],
  "bseTickers": [
	"INFY"
  ],
  "tickersWithStockExchangeNotMentioned": [
	"OLECTRA"
  ]
} 
 */
export const getTickersFromSearchParams = (tickers: string[] | null) => {
	const bseTickers: string[] = []
	const nseTickers: string[] = []
	const tickersWithStockExchangeNotMentioned: string[] = []
	tickers?.forEach((ticker: string) => {
		const tickerWithStockExchange = ticker.split(":")
		if (tickerWithStockExchange.length === 1) {
			tickersWithStockExchangeNotMentioned.push(tickerWithStockExchange[0])
		} else if (tickerWithStockExchange.length === 2) {
			if (tickerWithStockExchange[0].toUpperCase() === InStockExchange.NSE) {
				nseTickers.push(tickerWithStockExchange[1])
			} else if (
				tickerWithStockExchange[0].toUpperCase() === InStockExchange.BSE
			) {
				bseTickers.push(tickerWithStockExchange[1])
			}
		}
	})
	return { nseTickers, bseTickers, tickersWithStockExchangeNotMentioned }
}

/**
 * returns the query parameters from the url
 *
 * @param   url
 * @returns return object with key value pairs
 */
export const getSearchParams = (url: string) => {
	const { searchParams } = new URL(url)
	// eslint-disable-next-line
	const params: any = {}
	searchParams.forEach((value, key) => (params[key] = value))
	return params
}

export function getFormattedFromDate(from: string) {
	const fromDate = new Date(from + "T00:00:00")
	return fromDate
}

export function getFormattedToDate(to: string) {
	const toDate = new Date(to + "T23:59:59")
	return toDate
}

export function convertToUTC(fromDate: Date, toDate: Date, timeZone: string) {
	// Convert fromDate to UTC date with hours and minutes set to yesterday 12 am

	const fromUTC = zonedTimeToUtc(fromDate, timeZone)
	const toUTC = zonedTimeToUtc(toDate, timeZone)
	return [fromUTC, toUTC]
}

export function getDocumentPdfLink(
	countryCode: CountryCode,
	docS3Url?: string | null,
	docSourceUrl?: string | null,
	page?: string
) {
	const pdfLink =
		countryCode === CountryCode.IN && docS3Url && isPdf(docS3Url)
			? docS3Url.trim()
			: docSourceUrl && isPdf(docSourceUrl)
				? docSourceUrl.trim()
				: undefined
	return pdfLink && page ? `${pdfLink}#page=${page}` : pdfLink
}

export function getChunkLinkForPdfOrHtml(
	pdf_link?: string,
	html_link?: string,
	page?: string,
	chunkId?: string
) {
	return pdf_link
		? getPdfChunkLink(pdf_link, page)
		: getHtmlChunkLink(html_link, chunkId)
}

export function getChunkLinkInCompanyDocPage(
	countryCode: CountryCode,
	companyDocPageUrl?: string,
	page?: string,
	chunkId?: string
) {
	if (countryCode === CountryCode.IN) {
		return companyDocPageUrl && page
			? `${companyDocPageUrl}#page=${page}`
			: companyDocPageUrl
	} else if (countryCode === CountryCode.US) {
		return companyDocPageUrl && chunkId
			? `${companyDocPageUrl}#chunk${chunkId}`
			: companyDocPageUrl
	}
	return undefined
}

export function getPdfChunkLink(pdfLink?: string, page?: string) {
	return pdfLink && page ? `${pdfLink}#page=${page}` : pdfLink
}

export function getHtmlChunkLink(htmLink?: string, chunkId?: string) {
	return htmLink && chunkId ? `${htmLink}#chunk${chunkId}` : htmLink
}

export function getDocumentHtmlLink(
	docS3Url?: string | null,
	docSourceUrl?: string | null
) {
	// TODO: Change the order of the conditions after the US is enabled for company search
	if (docS3Url && isHtml(docS3Url)) {
		return docS3Url.trim()
	} else if (docSourceUrl && isHtml(docSourceUrl)) {
		return docSourceUrl.trim()
	} else {
		return undefined
	}
}

export function isHtml(input: string) {
	// trim is used to remove any leading or trailing spaces
	const ext = input.split(".").pop()?.trim()
	return ext === "htm" || ext === "html"
}

export function isPdf(input: string) {
	// trim is used to remove any leading or trailing spaces
	const ext = input.split(".").pop()?.trim()
	return ext === "pdf"
}

export const getLink = (
	linkType: "content-html" | "ai-insights",
	countryCode: CountryCode,
	documentType: string,
	documentId: string,
	queryParams?: string
) => {
	return `/api/${countryCode}/v0/documents/${documentType}/${documentId}/${linkType}${queryParams ? queryParams : ""}`
}

export const getHtmlLink = (
	countryCode: CountryCode,
	documentType: string,
	documentId: string
) => {
	return `/${countryCode.toLowerCase()}/filings/${documentType}/${documentId}/content`
}

export const mergeArrayByIntersection = (arr1: string[], arr2: string[]) => {
	return arr1.filter(value => arr2.includes(value))
}

/**
 * Input - 2024-04-24T12:30 (Date)
 * Output - APR 2024 (string)
 *
 * @param   Date
 * @returns return string with month and year in the format `<month> <year>`
 */
export const getFormattedMonthYear = (date: Date) => {
	const options: Intl.DateTimeFormatOptions = {
		month: "short",
		year: "numeric",
	}
	const formattedDate: string = date
		.toLocaleString("en-US", options)
		.toUpperCase()
		.replace(",", "")
	return formattedDate
}

/**
 * Input - 2024-06-13T12:30 (Date)
 * Output - JUN 13 2024 (string)
 *
 * @param   Date
 * @returns return string with month, date and year in the format `<month> <date> <year>`
 */
export const getFormattedDayMonthYear = (date: Date) => {
	const options: Intl.DateTimeFormatOptions = {
		day: "numeric",
		month: "short",
		year: "numeric",
	}
	const formattedDate: string = date
		.toLocaleString("en-US", options)
		.toUpperCase()
		.replace(",", "")

	return formattedDate
}

export const repairJson = (text: string) => {
	try {
		return parse(text.replace(/\\n/g, ""))
	} catch (error) {
		console.log("response is not JSON")
		throw new Error("response is not JSON")
	}
}

export function listToMap<T, K, V>(
	list: T[],
	keyFunc: (item: T) => K,
	valueFunc: (item: T) => V
): Map<K, V[]> {
	const map = new Map<K, V[]>()

	list.forEach(item => {
		const key = keyFunc(item)
		const value = valueFunc(item)

		if (!map.has(key)) {
			map.set(key, [value])
		} else {
			map.get(key)!.push(value)
		}
	})
	return map
}

export function getStartAndEndOfDate(date: Date, timeZone: string) {
	const start = new Date(date)
	start.setHours(0, 0, 0, 0)
	const end = new Date(date)
	end.setHours(23, 59, 59, 999)
	return [zonedTimeToUtc(start, timeZone), zonedTimeToUtc(end, timeZone)]
}

// TODO:: Add type in Announcement table
export function getApiDocumentType(
	dbDocumentType: string,
	countryCode: CountryCode
) {
	if (countryCode === CountryCode.IN) {
		if (!dbDocumentType || dbDocumentType === "") {
			return DocumentTypesIN.ANNOUNCEMENT
		}
		return dbDocumentType
	} else if (countryCode === CountryCode.US) {
		if (!dbDocumentType || dbDocumentType === "") {
			return DocumentTypesUS["8-K"]
		}
		return dbDocumentType
	}
}

export const getDocumentCompanyPageLink = (params: {
	countryCode: CountryCode
	documentType: string
	documentSlug?: string
	ticker?: string
	cik?: string
	companyName?: string // ToDo - Remove if not used
}) => {
	return `/${params.countryCode.toLowerCase()}/${params.ticker && params.ticker.trim() ? params.ticker : params.cik}/${params.documentType}/${params.documentSlug}`
}

export function slugify_array(my_array: (string | number)[]) {
	return my_array
		.filter(str => str)
		.map(
			str =>
				str
					.toString()
					.trim()
					.replace(/[^\w\s-]/g, "") // remove non alphanumeric characters, spaces, dashes
					.replace(/[\s_-]+/g, "-") // replace word separators (spaces, underscores, dashes) with a single dash
		)
		.join("-")
		.toLowerCase()
		.replace(/^-+|-+/g, "-") // replace consecutive dashes with a single dash
}

export async function withRetries<T>(
	task: () => Promise<T>,
	options: {
		maxRetries: number
		onRetry?: (attempt: number, error: Error) => void
	}
): Promise<T> {
	const { maxRetries, onRetry } = options
	let attempts = 0

	while (attempts <= maxRetries) {
		try {
			return await task()
		} catch (error) {
			attempts++
			if (attempts > maxRetries) {
				throw new Error(
					`Task failed after ${maxRetries} retries: ${(error as Error).message}`
				)
			}
			if (onRetry) {
				onRetry(attempts, error as Error)
			}
		}
	}
	throw new Error("Unexpected error in retry handler")
}
