"use client"
import { useAccount } from '@/client/store/account'
import { useCallback, useEffect, useState } from 'react'
import { useIsMobileView } from '@/client/hooks/use-is-mobile-view'

const CelloWidget: React.FC = () => {
    const { account } = useAccount()
    const [token, setToken] = useState<string | null>(null)
    const isMobileOrSmall = useIsMobileView()

    const updateCelloToken = useCallback(async () => {
        try {
            const res = await fetch('/api/cello/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (res.ok) {
                const data = await res.json()
                const newToken = data?.token
                await window.cello("updateToken", newToken)
                setToken(newToken)
            } else {
                throw new Error('Failed to fetch the new token')
            }
        } catch (error) {
            console.error('Error updating the token:', error)
        }
    }, [])

    const fetchToken = useCallback(async () => {
        try {
            const res = await fetch('/api/cello/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            if (res.ok) {
                const data = await res.json()
                setToken(data?.token)
            } else {
                // console.error('Failed to fetch the token.');
            }
        } catch (error) {
            console.error('Error fetching the token:', error)
        }
    }, [])

    useEffect(() => {
        if (account?.email) {
            fetchToken()
        }
    }, [account, fetchToken])

    useEffect(() => {
        if (token) {
            window.cello = window.cello || { cmd: [] }

            // eslint-disable-next-line
            window.cello.cmd.push(async function (cello: any) {
                try {
                    await cello.boot({
                        productId: process.env.NEXT_PUBLIC_CELLO_PRODUCT_ID,
                        token: token,
                        language: "en",
                        hideDefaultLauncher: isMobileOrSmall,
                        customLauncherSelector: '.celloReferralButton',
                        productUserDetails: {
                            firstName: account?.first_name,
                            lastName: account?.last_name,
                            email: account?.email,
                        },
                        onTokenExpiring: async () => {
                            console.log("Token expiring, updating token")
                            await updateCelloToken()
                        },
                        onTokenExpired: async () => {
                            console.log("Token expired, updating token")
                            await updateCelloToken()
                        }
                    })
                } catch (error) {
                    console.error('Failed to boot cello:', error)
                }
            })
        }
    }, [token, account, updateCelloToken])
    return null
}

export default CelloWidget
