import { useState, useEffect } from 'react'

export function useIsMobileView(breakpoint: number = 768): boolean {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < breakpoint)
    }
    // Check on mount
    checkIsMobile()
    // Add event listener
    window.addEventListener('resize', checkIsMobile)
    // Cleanup
    return () => window.removeEventListener('resize', checkIsMobile)
  }, [breakpoint])
  return isMobile
}
