import axios from "@/config/axios.config"
import { create } from "zustand"
import { InfosState } from "@/client/lib/types/onboarding-types"
import { z } from "zod"
import {
	DEFAULT_INVESTOR_TYPES,
	INVESTOR_TYPE_OTHER,
} from "@/client/store/local/onboarding"
import { UserBO } from "@/server/features/user/bo"
import {
	createWatchList,
	updateCompanyWatchList,
	updateNotificationSettings,
} from "@/client/lib/api-functions"
import { ICountry } from "@/client/constants/constants"
import { DocumentTypesIN, DocumentTypesUS } from "@/common/config"
import { withRetries } from "@/server/features/utils"
import { UpdateOp } from "@/server/features/watchlists/bo"

type Error = {
	status: boolean
	message?: string
}

type AccountStoreProps = {
	loading: boolean
	isEditing: boolean
	error: Error
	account?: UserBO
	setAccountData: (data: UserBO) => void
	getAccount: () => void
	updateUserAccount: (data: InfosState, newUser?: boolean) => Promise<boolean>
	resetErrors: () => void
	setIsEditing: (val: boolean) => void
}

const LOADING_DEFAULT = false
const ERROR_DEFAULT: Error = { status: false, message: undefined }
const ACCOUNT_DEFAULT = undefined
const WATCHLIST_DEFAULT_IN_COMPANIES = [
	"b9b17ea1c7874de79483",
	"w088mnnyc84ituekk3so",
	"35oovannmyn2v5i5vanf",
]
const WATCHLIST_DEFAULT_US_COMPANIES = [
	"epPs1xy7IL",
	"Nm0GZZiElx",
	"s9OdYg4LMh",
]

function formatUserData(data: InfosState) {
	return {
		preferred_country: data.preferred_country.toUpperCase(),
		investor_type: data.investor_type,
		first_name: data.first_name,
		last_name: data.last_name || "",
	}
}

async function createDefaultWatchlist(preferredCountry: ICountry) {
	const createWatchlistWithRetry = async () => {
		const watchlistResponse = await createWatchList(preferredCountry)
		if (!watchlistResponse?.data?.id) {
			throw new Error("Invalid watchlist response")
		}
		return watchlistResponse
	}

	const watchlistResponse = await withRetries(createWatchlistWithRetry, {
		maxRetries: 2,
		onRetry: (attempt, error) => {
			console.warn(`Attempt ${attempt} failed to create watchlist:`, error)
		},
	})

	return watchlistResponse.data.id
}

async function updateDefaultWatchlist(
	userData: InfosState,
	watchlistId: string
) {
	const isIndianUser = userData.preferred_country.toUpperCase() === "IN"
	await Promise.all([
		withRetries(
			() =>
				updateCompanyWatchList(
					userData.preferred_country as ICountry,
					watchlistId,
					isIndianUser
						? WATCHLIST_DEFAULT_IN_COMPANIES
						: WATCHLIST_DEFAULT_US_COMPANIES,
					UpdateOp.ADD
				),
			{ maxRetries: 2 }
		),
		withRetries(
			() =>
				updateNotificationSettings(
					userData.preferred_country as ICountry,
					watchlistId,
					isIndianUser
						? [
								DocumentTypesIN.EARNINGS_TRANSCRIPT,
								DocumentTypesIN.ANNUAL_REPORT,
								DocumentTypesIN.ANNOUNCEMENT,
							]
						: [
								DocumentTypesUS.EARNINGS_TRANSCRIPT,
								DocumentTypesUS["10-K"],
								DocumentTypesUS["8-K"],
							]
				),
			{ maxRetries: 2 }
		),
	])
}


async function setupNewUserDefaults(userData: InfosState) {
	const watchlistId = await createDefaultWatchlist(
		userData.preferred_country as ICountry
	)
	await updateDefaultWatchlist(userData, watchlistId)
}

export const useAccount = create<AccountStoreProps>(set => ({
	loading: LOADING_DEFAULT,
	error: ERROR_DEFAULT,
	account: ACCOUNT_DEFAULT,
	isEditing: false,
	setIsEditing: value => set({ isEditing: value }),
	setAccountData(data) {
		set({
			account: {
				...data,
				preferred_country: data.preferred_country.toLowerCase(),
			},
		})
	},
	getAccount: async () => {
		try {
			set({ loading: true })
			const response = await axios.get(`/api/users`)
			set({
				account: {
					...response.data.data,
					preferred_country: response.data.data.preferred_country.toLowerCase(),
				},
			})
		} catch (error) {
			console.error("Failed to load account details", error)
			set({
				error: { status: true, message: "Failed to load account details" },
			})
		} finally {
			set({ loading: false })
		}
	},
	updateUserAccount: async (
		userData: InfosState,
		newUser: boolean | undefined
	) => {
		try {
			set({ loading: true, error: ERROR_DEFAULT })
			const response = await axios.put(`/api/users`, formatUserData(userData))

			if (newUser) {
				try {
					await setupNewUserDefaults(userData)
				} catch (watchlistError) {
					console.error("Watchlist creation failed:", watchlistError)
					// Continue execution even if watchlist setup fails (not throwing error)
				}
			}

			set({ account: response.data.data, isEditing: false, loading: false })
			return true
		} catch (error) {
			set({
				error: { status: true, message: "Failed to save details." },
				loading: false,
			})
			return false
		}
	},
	resetErrors: () => {
		set({
			loading: LOADING_DEFAULT,
			error: ERROR_DEFAULT,
		})
	},
}))

export class AccountSchema {
	static FirstNameSchema = z
		.string()
		.min(3, {
			message: "Your first name should at least be 3 characters long.",
		})
		.max(100, {
			message: "Your first name should not exceed more than 100 characters.",
		})

	static LastNameSchema = z.string().optional().default("")

	static InvestorTypeSchema = z
		.object({
			investor_type: z.enum([INVESTOR_TYPE_OTHER, ...DEFAULT_INVESTOR_TYPES], {
				required_error: "Please select an option",
			}),
			investor_type_other: z
				.string()
				.min(3, {
					message: "Specific investor role should at least 3 characters long.",
				})
				.max(100, {
					message:
						"Specific investor role should not exceed more than 100 characters.",
				})
				.or(z.literal("")),
		})
		.superRefine((values, ctx) => {
			if (!values.investor_type) {
				ctx.addIssue({
					message: "Please select an option",
					code: z.ZodIssueCode.custom,
					path: ["investor_type"],
				})
			}
			if (
				values.investor_type == INVESTOR_TYPE_OTHER &&
				!values.investor_type_other
			) {
				ctx.addIssue({
					message: "Please enter your specific investor role.",
					code: z.ZodIssueCode.custom,
					path: ["investor_type_other"],
				})
			}
		})

	static PreferredCountrySchema = z.enum(["US", "IN", ""], {
		required_error: "Please select an option",
	})
}
